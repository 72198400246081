import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
//import reportWebVitals from './reportWebVitals';
import './locale/i18n'; 


const root = ReactDOM.createRoot(document.getElementById('root')); 
 
root.render(

  
    <Router> 
        <App /> 
    </Router>
);
//reportWebVitals(console.log);
