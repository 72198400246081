import Axios from 'axios'

const axios = Axios.create({
  //    baseURL: 'https://www.rimtic.com/rimtic-b/',
  // baseURL: 'http://127.0.0.1:8000/',
  baseURL: process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_LOCAL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },

  // withCredentials: true,
})

export default axios