import React from 'react';
import { useTranslation } from 'react-i18next';


const Footer = () => {
    const { t, i18n } = useTranslation();
    return (
            <p className='fontAlmari' style={{textAlign:'center', color:'red', fontSize:'12px'}}>{t('footer')}</p>

    );
};

export default Footer;