import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Entete from "./components/Entete";
import Footer from "./components/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import './css/style_cv_acc_fr.css'
import { useTranslation } from 'react-i18next';

// Lazy import components
const Home = lazy(() => import("./pages/Home"));
const OffreEmploiDetail = lazy(() => import("./pages/offreEmploiDetail"));
const AppelOffresDetail = lazy(() => import("./pages/appelOffresDetail"));
const AvisDetail = lazy(() => import("./pages/avisDetail"));
const OffreEmploiPublier = lazy(() => import("./pages/offreEmploiPublier"));
const AppelOffresPublier = lazy(() => import("./pages/appelOffresPublier"));
const AvisPublier = lazy(() => import("./pages/avisPublier"));
const ModelesCV = lazy(() => import("./pages/modelesCV"));
const Admin = lazy(() => import("./pages/pageAdmin/admin"));
const ModelesLettres = lazy(() => import("./pages/modelesLettres"));
const Contact = lazy(() => import("./pages/contact"));
const Login = lazy(() => import("./pages/login"));
const Article = lazy(() => import("./pages/article"));
const AdminAfficherDetailOffreEmploi = lazy(() => import('./pages/pageAdmin/adminAfficherDetailOffreEmploi'));
const AdminAfficherDetailAppeleOffer = lazy(() => import('./pages/pageAdmin/adminAfficherDetailAppeleOffer'));
const AdminAfficherDetailAvis = lazy(() => import('./pages/pageAdmin/adminAfficherDetailAvis'));
const AfficherOffresEmploi = lazy(() => import('./pages/pageAdmin/afficherOffresEmploi'));
const AfficherAppelsOffres = lazy(() => import('./pages/pageAdmin/afficherAppelsOffres'));
const AfficherSocietes = lazy(() => import('./pages/pageAdmin/afficherSocietes'));
const EditerOffreEmploi = lazy(() => import('./pages/pageAdmin/editerOffreEmploi'));
const EditerAppelOffres = lazy(() => import('./pages/pageAdmin/editerAppelOffres'));
const AfficherDetailSocietes = lazy(() => import('./pages/pageAdmin/afficherDetailSocietes'));
const EditeSociete = lazy(() => import('./pages/pageAdmin/editeSocite'));
const EditerAvisPublication = lazy(() => import('./pages/pageAdmin/editerAvisPublication'));
const ReussirVotreEntretien = lazy(() => import("./pages/reussirVotreEntretien"));
const AfficherTousAvis = lazy(() => import("./pages/pageAdmin/afficherTousAvis"));
const AccueilTraducteur = lazy(() => import("./pages/traduction/accueilTraducteur"));
const TraductionOffreEmploi = lazy(() => import("./pages/traduction/traductionOffreEmploi"));
const TraductionAppelOffre = lazy(() => import("./pages/traduction/traductionAppelOffre"));
const TraductionAvis = lazy(() => import("./pages/traduction/traductionAvis"));

function App() {
  const { i18n } = useTranslation();
  import(`./css/style_${i18n.language}.css`);
  return (
    <HelmetProvider>
      {window.location.href.includes('/tic') ? null : <Entete />}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route exact path="/:langue/" element={<Home />} />
          <Route exact path="/offre-emploi/:slug/:id" element={<OffreEmploiDetail />} />
          <Route exact path="/:langue/offre-emploi/:slug/:id" element={<OffreEmploiDetail />} />
          <Route exact path="/appel-offres/:slug/:id" element={<AppelOffresDetail />} />
          <Route exact path="/:langue/appel-offres/:slug/:id" element={<AppelOffresDetail />} />
          <Route exact path="/avis/:slug/:id" element={<AvisDetail />} />
          <Route exact path="/:langue/avis/:slug/:id" element={<AvisDetail />} />
          <Route exact path="/:langue/deposer_offre_emploi" element={<OffreEmploiPublier />} />
          <Route exact path="/:langue/deposer_appel_offres" element={<AppelOffresPublier />} />
          <Route exact path="/:langue/deposer_avis" element={<AvisPublier />} />
          <Route exact path="/modelesCV" element={<ModelesCV />} />
          <Route exact path="/modelesLettres" element={<ModelesLettres />} />
          <Route exact path="/reussirVotreEntretien" element={<ReussirVotreEntretien />} />
          <Route exact path="/article/:id" element={<Article />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route path="*" element={<Home />} />
          <Route path="/login" element={<Login />} />

          {/* Admin routes */}
          <Route path="/tic" element={<Admin />} />
          <Route exact path="/tic/demploie/:id" element={<AdminAfficherDetailOffreEmploi />} />
          <Route exact path="/tic/doffre/:id" element={<AdminAfficherDetailAppeleOffer />} />
          <Route exact path="/tic/davis/:id" element={<AdminAfficherDetailAvis />} />
          <Route exact path="/tic/afficherOffresEmploi" element={<AfficherOffresEmploi />} />
          <Route exact path="/tic/afficherAppelsOffres" element={<AfficherAppelsOffres />} />
          <Route exact path="/tic/afficherTousAvis" element={<AfficherTousAvis />} />
          <Route exact path="/tic/afficherSocietes" element={<AfficherSocietes />} />
          <Route exact path="/tic/demploie/modifier/:id" element={<EditerOffreEmploi />} />
          <Route exact path="/tic/doffre/modifier/:id" element={<EditerAppelOffres />} />
          <Route exact path="/tic/desociete/:id" element={<AfficherDetailSocietes />} />
          <Route exact path="/tic/dsociete/modifier/:id" element={<EditeSociete />} />
          <Route exact path="/tic/davis/modifier/:id" element={<EditerAvisPublication />} />

          {/* Translator routes */}
          <Route exact path="/trad" element={<AccueilTraducteur />} />
          <Route exact path="/traduction/offreEmploi/:id" element={<TraductionOffreEmploi />} />
          <Route exact path="/traduction/appelOffre/:id" element={<TraductionAppelOffre />} />
          <Route exact path="/traduction/avis/:id" element={<TraductionAvis />} />
        </Routes>
      </Suspense>
      <Footer />
    </HelmetProvider>
  );
}

export default App;
